<template>
  <div>
    <div class="d-flex mb-3">
      <router-link
        class="btn btn-primary mr-2"
        :to="{name: 'signing-add-tn'}"
      >
        Add TN
      </router-link>
      <router-link
        class="btn btn-primary mr-2"
        :to="{name: 'signing-import-tn'}"
      >
        Import TN
      </router-link>
      <!--      <div-->
      <!--        class="btn btn-primary mr-2"-->
      <!--        @click="searchByItem"-->
      <!--      >-->
      <!--        Search-->
      <!--      </div>-->
    </div>
    <div class="table-responsive">
      <table class="table table-hover text-nowrap">
        <thead>
          <tr>
            <th @click="sort('tn')">
              TN
              <i
                class="fa asc-desc-icon"
                aria-hidden="true"
                :class="currentSortDir==='ASC' && currentSort === 'tn' ? 'fa-arrow-up' : 'fa-arrow-down'"
              />
            </th>
            <th @click="sort('ocn')">
              OCN
              <i
                class="fa asc-desc-icon"
                aria-hidden="true"
                :class="currentSortDir==='ASC' && currentSort === 'ocn' ? 'fa-arrow-up' : 'fa-arrow-down'"
              />
            </th>
            <th @click="sort('lata')">
              LATA
              <i
                class="fa asc-desc-icon"
                aria-hidden="true"
                :class="currentSortDir==='ASC' && currentSort === 'lata' ? 'fa-arrow-up' : 'fa-arrow-down'"
              />
            </th>
            <th @click="sort('state')">
              State
              <i
                class="fa asc-desc-icon"
                aria-hidden="true"
                :class="currentSortDir==='ASC' && currentSort === 'state' ? 'fa-arrow-up' : 'fa-arrow-down'"
              />
            </th>
            <th @click="sort('rate_center')">
              Rate Center
              <i
                class="fa asc-desc-icon"
                aria-hidden="true"
                :class="currentSortDir==='ASC' && currentSort === 'rate_center' ? 'fa-arrow-up' : 'fa-arrow-down'"
              />
            </th>
            <th @click="sort('carrier')">
              Carrier
              <i
                class="fa asc-desc-icon"
                aria-hidden="true"
                :class="currentSortDir==='ASC' && currentSort === 'carrier' ? 'fa-arrow-up' : 'fa-arrow-down'"
              />
            </th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <a-auto-complete
                v-model:value="tn"
                class="ml-auto"
                style="width: 150px"
                placeholder="Search Tn"
                @change="searchInputChange"
              />
            </td>
            <td>
              <a-auto-complete
                v-model:value="ocn"
                class="ml-auto"
                style="width: 150px"
                placeholder="Search Ocn"
                @change="searchInputChange"
              />
            </td>
            <td>
              <a-auto-complete
                v-model:value="lata"
                class="ml-auto"
                style="width: 150px"
                placeholder="Search Lata"
                @change="searchInputChange"
              />
            </td>
            <td>
              <a-auto-complete
                v-model:value="state"
                class="ml-auto"
                style="width: 150px"
                placeholder="Search State"
                @change="searchInputChange"
              />
            </td>
            <td>
              <a-auto-complete
                v-model:value="rate_center"
                class="ml-auto"
                style="width: 150px"
                placeholder="Search Rate_center"
                @change="searchInputChange"
              />
            </td>
            <td>
              <a-auto-complete
                v-model:value="carrier"
                class="ml-auto"
                style="width: 250px"
                placeholder="Search Carrier"
                @change="searchInputChange"
              />
            </td>
          </tr>
          <tr
            v-for="item in items"
            :key="item.id"
          >
            <td>{{ item.tn }}</td>
            <td>{{ item.ocn }}</td>
            <td>{{ item.lata }}</td>
            <td>{{ item.state }}</td>
            <td>{{ item.rate_center }}</td>
            <td>{{ item.carrier }}</td>
            <td
              class="d-flex"
              width="120px"
            >
              <a-tooltip
                placement="top"
                class="pr-0"
              >
                <template #title>
                  Delete
                </template>
                <a
                  class="a_action_icon bg-secondary mb-2 mr-2"
                  @click="deleteItem(item)"
                >
                  <i
                    class="fa fa-trash"
                    aria-hidden="true"
                  />
                </a>
              </a-tooltip>
            </td>
          </tr>
        </tbody>
      </table>
      <a-pagination
        v-if="total"
        class="pagination-right"
        :default-current="1"
        :total="total"
        @change="pagination($event)"
      />
    </div>
  </div>
</template>

<script>
import axios from '@/axios'
import {Modal} from 'ant-design-vue'

export default {
  data() {
    return {
      step: 10,
      skip: 0,
      total: null,
      items: null,
      search: false,
      tn: '',
      ocn: '',
      rate_center: '',
      lata: '',
      state: '',
      carrier: '',
      currentSort: 'tn',
      currentSortDir: 'ASC'
    }
  },
  mounted() {
    this.loadItems()
  },
  methods: {
    sort(s) {
      if (s === this.currentSort) {
        this.currentSortDir = this.currentSortDir === 'ASC' ? 'DESC' : 'ASC';
      }
      this.currentSort = s;
      this.loadItems()
    },
    searchInputChange() {
      _.debounce(this.searchByItem(), 150)
    },
    searchByItem() {
      axios
          .get(`/signing/tn?tn=${this.tn}&ocn=${this.ocn}&rate_center=${this.rate_center}&state=${this.state}&lata=${this.lata}&carrier=${this.carrier}&order_dir=asc&order_by=created_on&take=10&skip=0`)
          .then(res => {
            this.items = res.data.data
            this.total = res.data.count > this.step ? res.data.count : null
                // this.tn = '',
                // this.ocn = '',
                // this.rate_center = '',
                // this.lata = '',
                // this.state = '',
                // this.carrier = ''
          })
          .catch((err) => {
            console.log(err)
          })
    },
    loadItems() {
      axios
          .get(`/signing/tn?order_dir=${this.currentSortDir}&order_by=${this.currentSort}&take=10&skip=${this.skip}`)
          .then((res) => {
            this.items = res.data.data
            this.total = res.data.count > this.step ? res.data.count :  res.data.count
          })
          .catch((err) => {
            console.log(err)
          })
    },
    deleteItem(item) {
      const vm = this
      Modal.confirm({
        title: 'Do you Want to delete this item?',
        onOk() {
          vm.deleteReq(item)
        },
        onCancel() {
          console.log('Cancel')
        },
        class: 'test',
      })
    },
    deleteReq(item) {
      axios
          .delete(`/signing/tn/${item.id}`)
          .then((res) => {
            this.$store.dispatch('deleted', item.tn)
            this.loadItems()
          })
          .catch((err) => {
            console.log(err)
          })
    },
    pagination(int) {
      this.skip = 0
      this.skip += this.step * int - this.step
      this.loadItems()
    },
  },
}
</script>

<style scoped>
  th {
    cursor: pointer;
  }

  .asc-desc-icon {
    margin-left: .3rem;
    color: #d0dcf1;
  }
  .pagination-right {
    text-align: right;
    margin-right: 210px;
  }
</style>